import Link from "next/link";

import { useI18n } from "utils/with-i18n.utils";

import { PaymentMethods } from "./FirstSection/PaymentMethods";

export const FourthSection = () => {
  const i18n = useI18n();

  return (
    <footer>
      <div className="flex flex-col mx-auto max-w-[1440px] xl:flex-row xl:justify-between xl:items-center">
        <p className="hidden mb-3 text-sm text-center xl:block xl:mb-0 xl:text-left">
          Copyright © {new Date().getFullYear()} Mobile Club SAS
        </p>

        <div className="flex justify-center">
          <PaymentMethods />
        </div>

        <p className="mb-3 text-sm text-center xl:hidden xl:mb-0 xl:text-left">
          Copyright © {new Date().getFullYear()} Mobile Club SAS
        </p>

        <nav className="flex flex-col justify-center lg:flex-row lg:space-x-4 xl:justify-end">
          <p className="pt-1 text-center xl:pt-0 xl:text-left">
            <Link href="/conditions-generales" passHref={true}>
              <a className="text-sm border-b border-black/25">
                {i18n.t("footer.cgu")}
              </a>
            </Link>
          </p>
          <p className="pt-3 pb-5 text-center lg:pt-1 xl:py-0 xl:text-left">
            <Link href="/confidentialite" passHref={true}>
              <a className="text-sm border-b border-black/25">
                {i18n.t("footer.privacy")}
              </a>
            </Link>
          </p>
        </nav>
      </div>
    </footer>
  );
};
