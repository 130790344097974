import * as Types from '../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetNavbarCustomerOrderQueryVariables = Types.Exact<{
  customerId: Types.Scalars['Int'];
}>;


export type GetNavbarCustomerOrderQuery = { __typename?: 'query_root', customer?: Types.Maybe<{ __typename?: 'customers', id: number, firstName?: Types.Maybe<string>, orders: Array<{ __typename?: 'orders', id: number, state?: Types.Maybe<{ __typename?: 'order_states', id: number, name: string }>, subscription: { __typename?: 'items', id: number, name: string, properties?: Types.Maybe<any>, sku: string, price?: Types.Maybe<number>, tax?: Types.Maybe<{ __typename?: 'taxes', id: number, amount: any }> } }> }> };


export const GetNavbarCustomerOrderDocument = gql`
    query getNavbarCustomerOrder($customerId: Int!) {
  customer: customers_by_pk(id: $customerId) {
    id
    firstName: first_name
    orders(
      where: {current_state: {name: {_nin: ["canceled", "terminated", "swap"]}}}
    ) {
      id
      state: current_state {
        id
        name
      }
      subscription {
        id
        name
        price: sell_price_in_cents_excl_tax
        tax {
          id
          amount
        }
        properties
        sku
      }
    }
  }
}
    `;
export function useGetNavbarCustomerOrderQuery(baseOptions: Apollo.QueryHookOptions<GetNavbarCustomerOrderQuery, GetNavbarCustomerOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavbarCustomerOrderQuery, GetNavbarCustomerOrderQueryVariables>(GetNavbarCustomerOrderDocument, options);
      }
export function useGetNavbarCustomerOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavbarCustomerOrderQuery, GetNavbarCustomerOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavbarCustomerOrderQuery, GetNavbarCustomerOrderQueryVariables>(GetNavbarCustomerOrderDocument, options);
        }
export type GetNavbarCustomerOrderQueryHookResult = ReturnType<typeof useGetNavbarCustomerOrderQuery>;
export type GetNavbarCustomerOrderLazyQueryHookResult = ReturnType<typeof useGetNavbarCustomerOrderLazyQuery>;
export type GetNavbarCustomerOrderQueryResult = Apollo.QueryResult<GetNavbarCustomerOrderQuery, GetNavbarCustomerOrderQueryVariables>;