/* eslint-disable tailwindcss/no-custom-classname */
import Script from "next/script";

import {
  TRUSTPILOT_BUSINESS_UNIT_ID,
  TrustpilotTemplate,
} from "shared/TrustpilotWidget/config";

export const Business = () => {
  return (
    <>
      <Script
        strategy="lazyOnload"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />

      <div
        className="hidden lg:block trustpilot-widget"
        data-locale="fr-FR"
        data-template-id={TrustpilotTemplate.Mini}
        data-businessunit-id={TRUSTPILOT_BUSINESS_UNIT_ID}
        data-style-height="150px"
        data-style-width="85%"
        data-theme="light"
      />

      <div
        className="lg:hidden trustpilot-widget"
        data-locale="fr-FR"
        data-template-id={TrustpilotTemplate.MicroStar}
        data-businessunit-id={TRUSTPILOT_BUSINESS_UNIT_ID}
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
      />
    </>
  );
};
