import { createContext, PropsWithChildren } from "react";

export type SettingsProviderProps = {
  isBlackFriday: boolean;
  isBusiness: boolean;
};

export const SettingsContext = createContext<SettingsProviderProps>({
  isBlackFriday: false,
  isBusiness: false,
});

export const SettingsProvider = ({
  children,
  ...props
}: PropsWithChildren<SettingsProviderProps>) => (
  <SettingsContext.Provider value={props}>{children}</SettingsContext.Provider>
);
