import Link from "next/link";

import { useI18n } from "utils/with-i18n.utils";

import { FooterLink } from "../../FooterLink";

export const AboutClub = () => {
  const i18n = useI18n();

  return (
    <section className="flex flex-col items-center space-y-5 lg:items-start">
      <h2 className="mb-2 text-lg font-semibold">
        {i18n.t("footer.aboutClub")}
      </h2>
      <ul className="space-y-2 list-none">
        <li>
          <Link href="/club" passHref={true}>
            <FooterLink>{i18n.t("footer.company.club")}</FooterLink>
          </Link>
        </li>
        <li>
          <Link href="/ecologie-location-telephone" passHref={true}>
            <FooterLink>{i18n.t("footer.company.environment")}</FooterLink>
          </Link>
        </li>
        <li>
          <Link href="/presse" passHref={true}>
            <FooterLink>{i18n.t("footer.company.press")}</FooterLink>
          </Link>
        </li>
        <li>
          <FooterLink href="/blog?utm_source=website&utm_medium=website+link&utm_campaign=generic_websitelink">
            {i18n.t("footer.company.blog")}
          </FooterLink>
        </li>
      </ul>
    </section>
  );
};
