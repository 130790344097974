import { AboutClub } from "./AboutClub";
import { ServicesAndOffers } from "./ServicesAndOffers";
import { SupportHelp } from "./SupportHelp";

export const B2C = () => (
  <div className="flex flex-col justify-between pb-6 w-full text-center lg:flex-row lg:space-x-14 lg:text-left xl:space-x-10">
    <AboutClub />
    <SupportHelp />
    <ServicesAndOffers />
  </div>
);
