import dayjs from "dayjs";

import {
  ACCEPTED_IBAN_COUNTRY_CODES,
  ACCEPTED_PHONE_COUNTRY_CODES,
  ACCEPTED_ZIPCODE_COUNTRY_CODES,
  IBAN_REGEXS,
  PHONE_REGEXS,
  ZIPCODE_REGEXS,
} from "constants/country-related";
import type { CountryCode } from "graphql.d";

type IsAcceptedArgs = [
  supportedCountryCodes: readonly CountryCode[],
  regexs: { [key in CountryCode]?: RegExp },
  value: string,
  index?: number,
];

export const getCountryCodeWhenAccepted: (
  ...args: IsAcceptedArgs
) => CountryCode | undefined = (
  supportedCountryCodes,
  regexs,
  value,
  index = 0,
) => {
  const countryCode = supportedCountryCodes[index];

  if (regexs[countryCode]?.test(value)) {
    return countryCode;
  }

  const nextIndex = index + 1;

  if (nextIndex === supportedCountryCodes.length) {
    return undefined;
  }

  return getCountryCodeWhenAccepted(
    supportedCountryCodes,
    regexs,
    value,
    nextIndex,
  );
};

const isAccepted = (...args: IsAcceptedArgs) =>
  Boolean(getCountryCodeWhenAccepted(...args));

export const isCellphone = (value: string) =>
  isAccepted(ACCEPTED_PHONE_COUNTRY_CODES, PHONE_REGEXS, value);

export const isZipcode = (value: string) =>
  isAccepted(ACCEPTED_ZIPCODE_COUNTRY_CODES, ZIPCODE_REGEXS, value);

export const isIbanAccepted = (value: string) =>
  isAccepted(ACCEPTED_IBAN_COUNTRY_CODES, IBAN_REGEXS, value);

export const isBirthdate = (value: string) => {
  const [day, month, year] = value.split("/");
  const dateString = `${month}/${day}/${year}`;

  return parseInt(year, 10) > 1900 && dayjs(dateString).isValid();
};

export const isAdult = (value: string) => {
  const [day, month, year] = value.split("/");

  return dayjs().diff(dayjs(`${month}/${day}/${year}`), "year") >= 18;
};

export const isSiret = (value: string) => {
  const unmaskedValue = value.replace(/\s/g, "");

  return unmaskedValue.length >= 9 && unmaskedValue.length <= 14;
};

export const isEmail = (value: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

export const isIBAN = async (value: string) => {
  const { electronicFormatIBAN, isValidIBAN } = await import(
    /* webpackChunkName: "ibantools" */
    "ibantools"
  );

  const formatted = electronicFormatIBAN(value);

  const blacklistedIbans = [
    electronicFormatIBAN("FR7616958000019216692582628")!,
  ];

  return (
    formatted && isValidIBAN(formatted) && !blacklistedIbans.includes(formatted)
  );
};

export const NAME_REGEX = /^[A-Za-zÀ-Þß-ÿ -]*$/;

export const EMAIL_REGEX = /^[\S]*?@[\S]*?\.[\S]+$/;
