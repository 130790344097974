import { Maybe } from "graphql";
import { useRouter } from "next/router";

import { Button } from "ui/Button";
import { useLogout } from "utils/useLogout";
import { useI18n } from "utils/with-i18n.utils";

import LogoutSVG from "./logout.svg";

type MobileLoginButtonProps = { customerId: Maybe<number> };

export const MobileLoginButton = ({ customerId }: MobileLoginButtonProps) => {
  const i18n = useI18n();

  const { push } = useRouter();

  const logout = useLogout();

  const handleLogout = () => logout();

  const handleGoToLogin = () => push("/login");

  if (customerId) {
    return (
      <button
        className="flex gap-x-2 items-center p-4 mb-28 bg-white/50 rounded-full border border-black"
        onClick={handleLogout}
      >
        <LogoutSVG />
        <p className="text-lg font-medium">{i18n.t("navbar.logout")}</p>
      </button>
    );
  }

  return (
    <Button alternative="secondary" onClick={handleGoToLogin}>
      {i18n.t("navbar.customerAccount")}
    </Button>
  );
};
