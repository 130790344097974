import { Logo } from "./Logo";
import LogoSmallScreen from "./Logo/mobile-club-logo-small-screen.svg";
import { TrustpilotReview } from "./TrustpilotReview";

export const FirstSection = () => (
  <section className="flex flex-col justify-center items-center w-full lg:items-start">
    <div className="hidden mb-4 lg:block">
      <Logo />
    </div>
    <div className="flex mb-4 lg:hidden">
      <LogoSmallScreen />
    </div>

    <div className="mt-4">
      <TrustpilotReview />
    </div>
  </section>
);
