import Image from "next/image";
import Link from "next/link";

import { useSettings } from "shared/SettingsProvider/useSettings";

export const NavLogo = () => {
  const { isBusiness } = useSettings();

  return (
    <Link href={isBusiness ? "/business" : "/"}>
      <a aria-label="return to home" className="flex items-center">
        <div className="relative w-[200px] h-[21.6px] lg:w-[240px] lg:h-[26px]">
          <Image
            src={
              isBusiness
                ? "/images/logo/logo-business.svg"
                : "/images/logo/logo-default.svg"
            }
            layout="fill"
            alt="mobile club logo"
          />
        </div>
      </a>
    </Link>
  );
};
