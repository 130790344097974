import { useRouter } from "next/router";
import { useState, useEffect } from "react";

export const useBurgerMenuActions = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const handleOpenBurgerMenu = () => setBurgerMenuOpen(true);

  const handleCloseBurgerMenu = () => setBurgerMenuOpen(false);

  const { asPath } = useRouter();

  // Close burger menu when route changes
  useEffect(() => {
    handleCloseBurgerMenu();
  }, [asPath]);

  return {
    handleCloseBurgerMenu,
    handleOpenBurgerMenu,
    isBurgerMenuOpen,
  };
};
