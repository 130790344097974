import { TrustpilotWidget } from "shared/TrustpilotWidget";
import { TrustpilotTemplate } from "shared/TrustpilotWidget/config";

export const B2C = () => (
  <>
    <TrustpilotWidget
      className="hidden lg:block"
      template={TrustpilotTemplate.Mini}
      height="115px"
      width="200px"
    />
    <TrustpilotWidget
      className="lg:hidden"
      template={TrustpilotTemplate.Mini}
      height="50px"
      width="200px"
    />
  </>
);
