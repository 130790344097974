import React, { PropsWithChildren, useState } from "react";

import { Newsletter } from "app/newsletter";
import {
  SettingsProvider,
  SettingsProviderProps,
} from "shared/SettingsProvider";
import { withApp } from "utils/with-app.utils";

import { Footer } from "./Footer";
import { Navbar } from "./Navbar";

type LayoutProps = {
  withBanner?: boolean;
  settings?: Partial<SettingsProviderProps>;
};

export const Layout = ({
  children,
  withBanner,
  settings,
}: PropsWithChildren<LayoutProps>) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSetIsOpen = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <SettingsProvider
      isBlackFriday={!!settings?.isBlackFriday && !settings?.isBusiness}
      isBusiness={!!settings?.isBusiness}
    >
      <Newsletter setIsOpen={handleSetIsOpen} isOpen={isOpen} />

      <Navbar withBanner={withBanner} />

      {children}

      <Footer />
    </SettingsProvider>
  );
};

export const AppLayout = withApp(Layout);
