import { useCallback } from "react";

import { BUSINESS_LOGIN_PATH } from "app/business/constants";
import { config } from "utils/config.utils";
import { removeCookie } from "utils/cookies.utils";

type UseLogoutProps = { isBusiness: boolean };

const DEFAULT_USELOGOUT_PROPS: UseLogoutProps = { isBusiness: false };

export const useLogout = ({
  isBusiness,
}: UseLogoutProps = DEFAULT_USELOGOUT_PROPS) => {
  const logout = useCallback(() => {
    const cookieKey = isBusiness
      ? config.b2b_session_cookie
      : config.session_cookie;
    removeCookie(cookieKey);
    window.location.replace(isBusiness ? BUSINESS_LOGIN_PATH : "/");
  }, [isBusiness]);

  return logout;
};
