import * as Types from '../graphql.d';

import { gql } from '@apollo/client';
export type StrapiImageFragment = { __typename?: 'UploadFileEntityResponse', data?: Types.Maybe<{ __typename?: 'UploadFileEntity', attributes?: Types.Maybe<{ __typename?: 'UploadFile', alternativeText?: Types.Maybe<string>, formats?: Types.Maybe<any> }> }> };

export const StrapiImageFragmentDoc = gql`
    fragment StrapiImage on UploadFileEntityResponse {
  data {
    attributes {
      alternativeText
      formats
    }
  }
}
    `;