import Link from "next/link";

import { useI18n } from "utils/with-i18n.utils";

import { FooterLink } from "../../FooterLink";

export const ServicesAndOffers = () => {
  const i18n = useI18n();

  return (
    <section className="flex flex-col items-center mt-9 space-y-5 lg:items-start lg:mt-0">
      <h2 className="mb-2 text-lg font-semibold">
        {i18n.t("footer.servicesAndOffers.title")}
      </h2>
      <ul className="space-y-2 list-none">
        <li>
          <Link href="/smartphone" passHref={true}>
            <FooterLink>
              {i18n.t("footer.servicesAndOffers.allSmartphones")}
            </FooterLink>
          </Link>
        </li>
        <li>
          <Link href="/comment-ca-marche" passHref={true}>
            <FooterLink>
              {i18n.t("footer.servicesAndOffers.mobileClub")}
            </FooterLink>
          </Link>
        </li>
        <li>
          <Link href="/smartphones-reconditionnes" passHref={true}>
            <FooterLink>
              {i18n.t("footer.servicesAndOffers.refurbished")}
            </FooterLink>
          </Link>
        </li>
        <li>
          <Link href="/modes-de-livraison" passHref={true}>
            <FooterLink>
              {i18n.t("footer.servicesAndOffers.delivery")}
            </FooterLink>
          </Link>
        </li>
        <li>
          <Link href="/modes-de-paiement" passHref={true}>
            <FooterLink>
              {i18n.t("footer.servicesAndOffers.paymentMethods")}
            </FooterLink>
          </Link>
        </li>
        <li>
          <Link href="https://www.mobile.club/business" passHref={true}>
            <FooterLink>
              {i18n.t("footer.servicesAndOffers.professionals")}
            </FooterLink>
          </Link>
        </li>
      </ul>
    </section>
  );
};
