import Link from "next/link";
import { PropsWithChildren } from "react";

import { FooterLink } from "shared/Footer/FooterLink";
import { HorizontalSeparator } from "ui/HorizontalSeparator";

import Arrow from "./arrow.svg";

type ArrowItemProps = PropsWithChildren<{
  itemLink: string;
}>;

export const ArrowItem = ({ itemLink, children }: ArrowItemProps) => (
  <Link href={itemLink} passHref>
    <div className="w-full lg:w-auto">
      <a className="flex flex-row justify-between my-4 w-full lg:hidden ">
        {children}
        <Arrow />
      </a>

      <HorizontalSeparator className="lg:hidden" isBlack />

      <FooterLink className="hidden lg:block">{children}</FooterLink>
    </div>
  </Link>
);
