import React from "react";

import { Button } from "ui/Button";
import { useI18n } from "utils/with-i18n.utils";

interface SurpriseProps {
  onClick: () => void;
}
export const Surprise = ({ onClick }: SurpriseProps) => {
  const i18n = useI18n();

  return (
    <div className="max-w-full font-semibold text-center">
      <div className="text-4xl md:text-6xl">😍</div>
      <p className="pt-6 text-2xl md:text-3xl">
        {i18n.t("newsletter.surprise.question")}
      </p>
      <Button
        className="mt-9 mb-7 text-xs md:py-3.5 md:px-36 md:text-lg"
        onClick={onClick}
      >
        {i18n.t("newsletter.surprise.yes")}
      </Button>
    </div>
  );
};
