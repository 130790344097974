import Link from "next/link";

import { useI18n } from "utils/with-i18n.utils";

import { NavLink } from "./NavLink";

export const B2CNavLinks = () => {
  const i18n = useI18n();

  return (
    <>
      <Link href="/smartphone" passHref>
        <NavLink>{i18n.t("navbar.ourSmartphones")}</NavLink>
      </Link>

      <NavLink href="/business">{i18n.t("navbar.entreprise")}</NavLink>

      <Link href="/club" passHref>
        <NavLink>{i18n.t("navbar.club")}</NavLink>
      </Link>

      <Link href="/ecologie-location-telephone" passHref>
        <NavLink>{i18n.t("navbar.environment")}</NavLink>
      </Link>

      <NavLink
        href="/blog?utm_source=website&utm_medium=website+link&utm_campaign=generic_websitelink"
        className="lg:hidden xl:flex"
      >
        {i18n.t("navbar.blog")}
      </NavLink>
    </>
  );
};
