import { useSettings } from "shared/SettingsProvider/useSettings";

import { B2C } from "./B2C";
import { Business } from "./Business";

export const TrustpilotReview = () => {
  const { isBusiness } = useSettings();

  return isBusiness ? <Business /> : <B2C />;
};
