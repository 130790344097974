import { useSettings } from "shared/SettingsProvider/useSettings";

import { B2C } from "./B2C";
import { Business } from "./Business";

export const SecondSection = () => {
  const { isBusiness } = useSettings();

  return (
    <div className="flex flex-col whitespace-nowrap lg:flex-row">
      {isBusiness ? <Business /> : <B2C />}
    </div>
  );
};
