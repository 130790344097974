import { useSettings } from "shared/SettingsProvider/useSettings";

import { B2CNavLinks } from "./B2CNavLinks";
import { BusinessNavLinks } from "./BusinessNavLinks";

export const NavLinks = () => {
  const { isBusiness } = useSettings();

  return isBusiness ? <BusinessNavLinks /> : <B2CNavLinks />;
};
