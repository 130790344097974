import { useSettings } from "shared/SettingsProvider/useSettings";

import { BurgerMenuIcon, BurgerMenuMode } from "./BurgerMenuIcon";

type BurgerMenuProps = {
  onCloseBurgerMenu: () => void;
  onOpenBurgerMenu: () => void;
  isBurgerMenuOpen: boolean;
};

export const BurgerMenu = ({
  onCloseBurgerMenu,
  onOpenBurgerMenu,
  isBurgerMenuOpen,
}: BurgerMenuProps) => {
  const { isBusiness } = useSettings();

  return (
    <div className="flex items-center lg:hidden">
      <button
        className="flex justify-between lg:hidden"
        onClick={isBurgerMenuOpen ? onCloseBurgerMenu : onOpenBurgerMenu}
        aria-label="menu"
      >
        <BurgerMenuIcon
          isBurgerMenuOpen={isBurgerMenuOpen}
          mode={isBusiness ? BurgerMenuMode.Dark : BurgerMenuMode.Light}
        />
      </button>
    </div>
  );
};
