import { useCallback, useEffect, useState } from "react";

const TWO_SECONDS = 2000;

export const useRevealPreview = (hasDevice: boolean) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const timeOut = setTimeout(() => {
    if (!isHovered) {
      setIsPreviewOpen(false);
    }
  }, TWO_SECONDS);

  // Reveal preview if user has a device
  useEffect(() => {
    if (hasDevice) {
      setIsPreviewOpen(true);
    }

    return clearTimeout(timeOut);
  }, [hasDevice]);

  // Reveal preview on hover and hide it after 2 seconds when user is not hovering
  useEffect(() => {
    if (isHovered) {
      setIsPreviewOpen(true);
      return clearTimeout(timeOut);
    }
  }, [isHovered, isPreviewOpen, timeOut]);

  const handleShowPreview = useCallback(() => {
    setIsHovered(true);
    clearTimeout(timeOut);
  }, [timeOut]);

  const handlehidePreview = useCallback(() => setIsHovered(false), []);

  return { isPreviewOpen, handleShowPreview, handlehidePreview };
};
