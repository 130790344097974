import * as Types from '../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribeToNewsletterMutationVariables = Types.Exact<{
  mail: Types.Scalars['String'];
}>;


export type SubscribeToNewsletterMutation = { __typename?: 'mutation_root', nest_newsletterSubscribe: { __typename?: 'SuccessObjectDto', success: boolean } };


export const SubscribeToNewsletterDocument = gql`
    mutation subscribeToNewsletter($mail: String!) {
  nest_newsletterSubscribe(mail: $mail) {
    success
  }
}
    `;
export function useSubscribeToNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>(SubscribeToNewsletterDocument, options);
      }
export type SubscribeToNewsletterMutationHookResult = ReturnType<typeof useSubscribeToNewsletterMutation>;
export type SubscribeToNewsletterMutationResult = Apollo.MutationResult<SubscribeToNewsletterMutation>;
export type SubscribeToNewsletterMutationOptions = Apollo.BaseMutationOptions<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>;