import { useI18n } from "utils/with-i18n.utils";

import { ArrowItem } from "./ArrowItem";

export const Business = () => {
  const i18n = useI18n();

  return (
    <div className="flex flex-col justify-center items-center w-full font-[500] lg:flex-row lg:gap-8 lg:my-14">
      <ArrowItem itemLink={"/business"}>
        {i18n.t("footer.business.business")}
      </ArrowItem>

      <ArrowItem itemLink="/business/gestion-de-flotte">
        {i18n.t("footer.business.fleetManagement")}
      </ArrowItem>

      <ArrowItem itemLink="/business/catalogue">
        {i18n.t("footer.business.ourDevices")}
      </ArrowItem>

      <ArrowItem itemLink="/">
        {i18n.t("footer.business.individualsOffer")}
      </ArrowItem>

      <ArrowItem itemLink="/business/login">
        {i18n.t("footer.business.businessSpace")}
      </ArrowItem>
    </div>
  );
};
