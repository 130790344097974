import cx from "classnames";
import { Maybe } from "graphql";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { Shadow } from "ui/Button";
import { applyTax } from "utils/price.utils";
import { useLogout } from "utils/useLogout";

import { useGetNavbarCustomerOrderQuery } from "../getNavbarCustomerOrder.generated";
import LogoutSVG from "../logout.svg";

import { ContactUs } from "./ContactUs";
import { LoginButton } from "./LoginButton";
import { OrderPreview } from "./OrderPreview";
import { useRevealPreview } from "./useRevealPreview";

type LoginBlockProps = {
  customerId: Maybe<number>;
};

export const LoginBlock = ({ customerId }: LoginBlockProps) => {
  const logout = useLogout();

  const { isBusiness } = useSettings();

  const hasSession = !!customerId;

  const { data: { customer } = {} } = useGetNavbarCustomerOrderQuery({
    variables: { customerId: customerId! },
    skip: !customerId,
  });

  const orders = customer?.orders || [];
  const device = orders?.[0]?.subscription;
  const hasDevice = !!device;
  const subscriptionPrice = applyTax(device?.price ?? 0, device?.tax?.amount);

  const { handleShowPreview, handlehidePreview, isPreviewOpen } =
    useRevealPreview(hasDevice);

  const incompleteOrders = orders.filter(
    ({ state }) =>
      state && (state.name === "draft" || state.name === "created"),
  );

  const completeOrders = orders.filter(
    ({ state }) => state && state.name !== "draft" && state.name !== "created",
  );

  const hasIncompleteOrder =
    !completeOrders.length && !!incompleteOrders.length;

  const handleLogout = () => logout();

  return (
    <div
      className={cx(
        "flex relative items-center",
        isBusiness ? "lg:pl-[44px]" : "ml-2 lg:ml-0 gap-x-5",
      )}
    >
      <LoginButton
        hasSession={hasSession}
        customerName={customer?.firstName || ""}
        hasIncompleteOrder={hasIncompleteOrder}
        onMouseEnter={handleShowPreview}
        onMouseLeave={handlehidePreview}
        className={
          isBusiness
            ? "lg:py-[9.6px] px-0 lg:px-6 lg:mb-[0px] lg:ml-[12px] text-[18px] leading-5 font-[600]"
            : "lg:px-6 px-2 py-2"
        }
      />

      {isBusiness && (
        <ContactUs
          className="py-[12px] px-[24px] lg:py-[11px] lg:ml-[12px]"
          shadow={Shadow.None}
        />
      )}

      {hasSession && (
        <button onClick={handleLogout} className="hidden lg:block">
          <LogoutSVG />
        </button>
      )}

      {hasDevice && isPreviewOpen && hasIncompleteOrder && (
        <OrderPreview
          color={device.properties?.color}
          handleShowPreview={handleShowPreview}
          handlehidePreview={handlehidePreview}
          model={device.properties?.model}
          price={subscriptionPrice}
          storage={device.properties?.storage}
          sku={device.sku}
        />
      )}
    </div>
  );
};
