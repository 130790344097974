import copy from "copy-to-clipboard";
import Copy from "images/newsletter/copy.svg";
import React, { useState } from "react";

import { Button } from "ui/Button";
import { useI18n } from "utils/with-i18n.utils";

interface ThanksProps {
  onClick: () => void;
}
export const Thanks = ({ onClick }: ThanksProps) => {
  const i18n = useI18n();
  const [showPopup, setShowPopup] = useState(false);
  const promoCode = i18n.t("newsletter.thanks.promoCode");

  const handleCopy = () => {
    copy(promoCode);
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 1000); // Hide after 2 seconds
  };

  return (
    <div className="text-center">
      <div className="px-8 text-xl font-semibold md:px-20 md:text-4xl">
        {i18n.t("newsletter.thanks.welcome")}
      </div>
      <p className="px-8 pt-6 text-sm md:px-20 md:text-lg">
        {i18n.t("newsletter.thanks.takeAdvantage")}{" "}
        <span className="font-semibold">
          {i18n.t("newsletter.thanks.reduction")}
        </span>{" "}
        {i18n.t("newsletter.thanks.followingCode")}
      </p>
      <div
        className="inline-flex items-center py-3.5 px-6 mt-6 text-lg font-semibold rounded-xl border border-dashed md:text-2xl"
        style={{
          color: "rgba(125, 137, 255, 1)",
          borderColor: "rgba(125, 137, 255, 1)",
        }}
      >
        <span>{i18n.t("newsletter.thanks.promoCode")}</span>
        <div className="flex relative justify-center ml-12">
          <Copy className="hover:cursor-pointer" onClick={handleCopy} />
          <div
            className={`absolute py-1 px-6 mt-8 text-sm text-white rounded-xl transition-opacity duration-300 ease-out ${
              showPopup ? "opacity-100" : "opacity-0"
            }`}
            style={{ backgroundColor: "rgba(125, 137, 255, 1)" }}
          >
            <span>&#10003;</span>
          </div>
        </div>
      </div>

      <Button
        className="py-3.5 mt-9 mb-7 text-sm md:px-36 md:text-lg"
        onClick={onClick}
      >
        {i18n.t("newsletter.thanks.returnToSite")}
      </Button>
    </div>
  );
};
