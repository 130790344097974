import cx from "classnames";
import Marquee from "react-fast-marquee";

import { useSettings } from "shared/SettingsProvider/useSettings";

import BannerStarSVG from "./banner-star.svg";

type BannerProps = {
  content: string[];
  direction?: "left" | "right";
  separator?: "star" | "dot";
  speed?: number;
};

export const Banner = ({
  content,
  direction = "left",
  separator = "star",
  speed = 75,
}: BannerProps) => {
  const { isBlackFriday } = useSettings();

  const marqueeSeparator =
    separator === "star" ? (
      <BannerStarSVG />
    ) : (
      <div className="text-xl font-semibold">·</div>
    );

  return (
    // div with min-height to prevent content from jumping when marquee is rendered
    <div
      className={cx(
        "py-3 min-h-[3.25rem] border-y-[1px]",
        isBlackFriday ? "bg-black border-y-white" : "bg-yellow border-y-black",
      )}
    >
      <Marquee gradient={false} speed={speed} direction={direction} autoFill>
        {content.map((item, index) => (
          <p
            key={index}
            className={cx(
              "flex gap-x-3 items-center ml-2 text-xs font-medium",
              { "text-white": isBlackFriday },
            )}
          >
            <span>{item}</span>
            <span>{marqueeSeparator}</span>
          </p>
        ))}
      </Marquee>
    </div>
  );
};
