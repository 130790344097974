import { match } from "ts-pattern";

import BurgerMenuWhite from "./burger-menu-white.svg";
import BurgerMenuBlack from "./burger-menu.svg";
import CrossWhite from "./cross-white.svg";
import CrossBlack from "./cross.svg";

export enum BurgerMenuMode {
  Dark,
  Light,
}

type BurgerMenuIconProps = {
  isBurgerMenuOpen: boolean;
  mode: BurgerMenuMode;
};

export const BurgerMenuIcon = ({
  isBurgerMenuOpen,
  mode,
}: BurgerMenuIconProps) =>
  match({ isBurgerMenuOpen, mode })
    .with(
      {
        isBurgerMenuOpen: true,
        mode: BurgerMenuMode.Light,
      },
      () => <CrossBlack />,
    )
    .with(
      {
        isBurgerMenuOpen: true,
        mode: BurgerMenuMode.Dark,
      },
      () => <CrossWhite />,
    )
    .with(
      {
        isBurgerMenuOpen: false,
        mode: BurgerMenuMode.Light,
      },
      () => <BurgerMenuBlack />,
    )
    .with(
      {
        isBurgerMenuOpen: false,
        mode: BurgerMenuMode.Dark,
      },
      () => <BurgerMenuWhite />,
    )
    .otherwise(() => null);
