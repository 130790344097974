import * as Types from '../../../graphql.d';

import { StrapiImageFragment } from '../../strapi-image.fragment.generated';
import { gql } from '@apollo/client';
import { StrapiImageFragmentDoc } from '../../strapi-image.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDeviceCombinedImageQueryVariables = Types.Exact<{
  sku: Types.Scalars['String'];
}>;


export type GetDeviceCombinedImageQuery = { __typename?: 'query_root', smartphoneSubscriptions?: Types.Maybe<{ __typename?: 'SmartphoneSubscriptionEntityResponseCollection', data: Array<{ __typename?: 'SmartphoneSubscriptionEntity', attributes?: Types.Maybe<{ __typename?: 'SmartphoneSubscription', smartphoneVariant?: Types.Maybe<{ __typename?: 'SmartphoneVariantEntityResponse', data?: Types.Maybe<{ __typename?: 'SmartphoneVariantEntity', attributes?: Types.Maybe<{ __typename?: 'SmartphoneVariant', combinedPicture?: Types.Maybe<(
                { __typename?: 'UploadFileEntityResponse' }
                & StrapiImageFragment
              )> }> }> }> }> }> }> };


export const GetDeviceCombinedImageDocument = gql`
    query getDeviceCombinedImage($sku: String!) {
  smartphoneSubscriptions(filters: {sku: {eq: $sku}}) {
    data {
      attributes {
        smartphoneVariant {
          data {
            attributes {
              combinedPicture {
                ...StrapiImage
              }
            }
          }
        }
      }
    }
  }
}
    ${StrapiImageFragmentDoc}`;
export function useGetDeviceCombinedImageQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceCombinedImageQuery, GetDeviceCombinedImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceCombinedImageQuery, GetDeviceCombinedImageQueryVariables>(GetDeviceCombinedImageDocument, options);
      }
export function useGetDeviceCombinedImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceCombinedImageQuery, GetDeviceCombinedImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceCombinedImageQuery, GetDeviceCombinedImageQueryVariables>(GetDeviceCombinedImageDocument, options);
        }
export type GetDeviceCombinedImageQueryHookResult = ReturnType<typeof useGetDeviceCombinedImageQuery>;
export type GetDeviceCombinedImageLazyQueryHookResult = ReturnType<typeof useGetDeviceCombinedImageLazyQuery>;
export type GetDeviceCombinedImageQueryResult = Apollo.QueryResult<GetDeviceCombinedImageQuery, GetDeviceCombinedImageQueryVariables>;