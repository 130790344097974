import cx from "classnames";
import { useRouter } from "next/router";
import { DetailedHTMLProps, ButtonHTMLAttributes } from "react";

import { Button, Shadow } from "ui/Button";
import { useI18n } from "utils/with-i18n.utils";

type ContactUsProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  isBurgerMenuOpen?: boolean;
  shadow?: Shadow;
};

export const ContactUs = ({
  isBurgerMenuOpen = false,
  shadow,
  className,
}: ContactUsProps) => {
  const i18n = useI18n();
  const { push } = useRouter();

  return (
    <Button
      onClick={() => push("/business/contacter-un-conseiller")}
      className={cx(className, !isBurgerMenuOpen && "hidden lg:block")}
      alternative={"primary"}
      shadow={shadow}
    >
      <div className="flex flex-row gap-x-3 items-center">
        <span
          className={cx(
            "text-[18px] font-[500] leading-5",
            !isBurgerMenuOpen && "hidden lg:block",
          )}
        >
          {i18n.t("navbar.business.contactUs")}
        </span>
      </div>
    </Button>
  );
};
