import { Maybe } from "graphql";

import { TranslationTable } from "utils/strapi/translation-table";

import { BusinessStatus, DashboardOption, PanelType } from "./types";

type DeviceSectionField =
  | "brand"
  | "chip"
  | "color"
  | "cpu"
  | "display"
  | "end"
  | "gpu"
  | "keyboard"
  | "memory"
  | "model"
  | "price"
  | "start"
  | "orderCode"
  | "storage"
  | "subscription";

type PhonePlanSectionField = "phoneNumber" | "iccid";
type SubscriptionSectionField =
  | "orderCode"
  | "subscriptionStart"
  | "subscriptionEnd"
  | "monthlyPrice";

type UserSectionField =
  | "userName"
  | "firstName"
  | "lastName"
  | "status"
  | "phoneNumber"
  | "totalItems"
  | "email"
  | "createdAt";

type UserDeviceInfoSectionField = "deviceName" | "serial" | "deviceSpecs";

export type SectionField =
  | DeviceSectionField
  | SubscriptionSectionField
  | PhonePlanSectionField
  | UserSectionField
  | UserDeviceInfoSectionField;

export type SectionFieldValues = {
  [k in SectionField]?: Maybe<string>;
};

export type Section =
  | "specifications"
  | "deviceUser"
  | "userCoordinates"
  | "phonePlan"
  | "consumption"
  | "subscription"
  | "quotas";

export const SECTION_FIELDS: {
  specifications: DeviceSectionField[];
  subscription: DeviceSectionField[];
  userCoordinates: UserSectionField[];
  userDetailCoordinates: UserSectionField[];
  userDeviceInfos: UserDeviceInfoSectionField[];
} = {
  specifications: [
    "brand",
    "model",
    "display",
    "chip",
    "cpu",
    "gpu",
    "memory",
    "storage",
    "keyboard",
  ],
  subscription: ["orderCode", "start", "end", "subscription", "price"],
  userCoordinates: [
    "userName",
    "status",
    "totalItems",
    "phoneNumber",
    "createdAt",
  ],
  userDetailCoordinates: ["firstName", "lastName", "email", "phoneNumber"],
  userDeviceInfos: ["deviceName", "serial", "deviceSpecs"],
};

export const SECTION_FIELD_LABELS: {
  [k in SectionField]: string;
} = {
  brand: "Marque",
  chip: "Processeur",
  color: "Couleur",
  cpu: "CPU",
  display: "Écran",
  email: "Email",
  end: "Fin d'endagement",
  gpu: "GPU",
  iccid: "business.phonePlans.iccid",
  keyboard: "Clavier",
  memory: "Mémoire",
  model: "modèle",
  monthlyPrice: "business.subscription.monthlyPrice",
  orderCode: "business.subscription.orderCode",
  phoneNumber: "business.phonePlans.phoneNumber",
  price: "Prix mensuel",
  start: "Début de location",
  storage: "Stockage",
  subscription: "Début d'abonnement",
  subscriptionStart: "business.subscription.subscriptionStart",
  subscriptionEnd: "business.subscription.subscriptionEnd",
  firstName: "business.common.firstName",
  lastName: "business.common.lastName",
  userName: "business.common.userName",
  totalItems: "business.user.associatedItems",
  createdAt: "business.user.createdAt",
  status: "business.user.status",
  deviceName: "business.user.device.deviceName",
  serial: "business.user.device.serial",
  deviceSpecs: "business.user.device.specs",
};

/* ********************************************************
 *                         PANEL                          *
 **********************************************************/

export const PANEL_TITLES: {
  [k in PanelType]: string;
} = {
  laptop: "Ordinateur portable",
  ["phone-plan"]: "business.phonePlans.title",
  device: "Smartphone",
  tablet: "Tablette",
  user: "Utilisateur",
};

/* ********************************************************
 *                       DASHBOARD                        *
 **********************************************************/

export type PhoneNumberValue = {
  number: string;
  canActivateSim: boolean;
  orderId: number;
  isEsim: boolean;
};

export type SubscriptionValue = {
  asset?: string;
  name?: string;
  details?: string;
};

export type DashboardEntry = {
  actions?: boolean;
  amount?: string;
  code?: string;
  createdAt?: string;
  email?: Maybe<string>;
  firstName?: string;
  id: number;
  lastName?: string;
  mobile?: string;
  orderStatus?: BusinessStatus;
  phoneNumber?: PhoneNumberValue | string;
  phoneStatus?: BusinessStatus;
  serial?: string;
  status?: BusinessStatus;
  subscription?: SubscriptionValue;
  type?: string;
  userName?: string;
  totalItems?: number;
};

export type ColumnId = Exclude<keyof DashboardEntry, "id">;

export type ColumnIds = ColumnId[];

const DEVICES_COLUMNS: ColumnIds = [
  "subscription",
  "serial",
  "status",
  "userName",
];

const PHONE_PLANS_COLUMNS: ColumnIds = [
  "subscription",
  "phoneNumber",
  "phoneStatus",
  "userName",
];

const USERS_COLUMNS: ColumnIds = ["userName", "totalItems", "createdAt"];

const INVOICES_COLUMNS: ColumnIds = [
  "code",
  "createdAt",
  "amount",
  "status",
  "actions",
];

export const COLUMNS: {
  [k in DashboardOption]: ColumnIds;
} = {
  ["phone-plans"]: PHONE_PLANS_COLUMNS,
  devices: DEVICES_COLUMNS,
  invoices: INVOICES_COLUMNS,
  users: USERS_COLUMNS,
};

/* ********************************************************
 *                       STORE                        *
 **********************************************************/

// First Month Price is upfront for physical and access for sims
export const UPFRONT_ITEM_TYPES = ["Access", "Upfront"];

export const PHYSICAL_CATALOG_DEVICE_TYPE = [
  "laptop",
  "tablet",
  "device",
] as const;

export type DeviceType = (typeof PHYSICAL_CATALOG_DEVICE_TYPE)[number];

export const STORE_CATEGORIES = [
  ...PHYSICAL_CATALOG_DEVICE_TYPE,
  "phone-plan",
] as const;

export const CART_OPTIONS = ["cart", "assign", "shipping", "confirm"] as const;

export type CartOption = (typeof CART_OPTIONS)[number];

export const DEVICES_ALTERNATIVES = {
  iphone7: "iphone7",
  iphone7_plus: "iphone7",
  iphone8: "iphone8",
  iphone8_plus: "iphone8",
  iphonex: "iphonex",
  iphonexr: "iphonexr",
  iphonexs: "iphonexs",
  iphonexs_max: "iphonexs",
  iphone11: "iphone11",
  iphone11pro: "iphone11pro",
  iphone11pro_max: "iphone11pro",
  iphone12: "iphone12",
  iphone12mini: "iphone12",
  iphone12pro: "iphone12pro",
  iphone12pro_max: "iphone12pro",
  iphone13: "iphone13",
  iphone13mini: "iphone13",
  iphone13pro: "iphone13pro",
  iphone13pro_max: "iphone13pro",
  galaxy_s9: "galaxy_s9",
  galaxy_s9_plus: "galaxy_s9",
  galaxy_s10: "galaxy_s10",
  galaxy_s10_plus: "galaxy_s10",
  galaxy_s20: "galaxy_s20",
  galaxy_s21: "galaxy_s21",
};

export const BUSINESS_STORE_TRANSLATIONS: TranslationTable[] = [
  "business-space",
  "product",
  "device",
];

/* ********************************************************
 *                       PATHS                        *
 **********************************************************/
export const BUSINESS_PATH = "/business";

export const BUSINESS_STORE_PATH = `${BUSINESS_PATH}/store`;

export const BUSINESS_LOGIN_PATH = `${BUSINESS_PATH}/login`;

export const CSV_EXPORT_ENDPOINTS = {
  Devices: "/b2b/csv-export/devices",
  PhonePlans: "/b2b/csv-export/phone-plans",
};
