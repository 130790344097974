import Image, { ImageProps, StaticImageData } from "next/image";

type OverridenImageProps = "src" | "alt";

type StaticImageProps = {
  src: StaticImageData;
  alt: string;
} & Omit<ImageProps, OverridenImageProps>;

/**
 * Custom StaticImage component that uses the Next.js Image component
 * It is used to automatically optimize images with Next.js accross the app
 * If priority is given to the image, it will not be blurred but initially loaded which increases the loading time
 * @param src - The image source that must be a static import to allow automatic optimization from nextjs
 * @param alt - The image alt text that is used for SEO
 */
export const StaticImage = ({ src, alt, ...props }: StaticImageProps) => {
  // Double check the props so we cannot override the behavior with typescript
  if (typeof src === "string") {
    throw new Error(
      "StaticImage does not support remote images, please provide a statically imported image",
    );
  }

  if (!alt) {
    throw new Error("Images require an alt attribute for SEO purposes");
  }

  return <Image src={src} alt={alt} {...props} />;
};
