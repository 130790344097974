import { first } from "lodash";
import { useRouter } from "next/router";

import { useGetDeviceCombinedImageQuery } from "shared/Navbar/LoginBlock/get-device-combined-image.query.generated";
import { StrapiImage, stripMetadata } from "ui/StrapiImage";
import { formatToCurrency } from "utils/price.utils";
import { useI18n } from "utils/with-i18n.utils";

type OrderPreviewProps = {
  color: string;
  handleShowPreview: () => void;
  handlehidePreview: () => void;
  model: string;
  price: number;
  storage: string;
  sku: string;
};

export const OrderPreview = ({
  color,
  handleShowPreview,
  handlehidePreview,
  model,
  price,
  sku,
  storage,
}: OrderPreviewProps) => {
  const i18n = useI18n();
  const { push } = useRouter();

  const handleGoToFunnel = () => push("/commander/recapitulatif");

  const { data } = useGetDeviceCombinedImageQuery({
    variables: { sku },
    fetchPolicy: "cache-first",
  });

  const stripped = stripMetadata(
    first(data?.smartphoneSubscriptions?.data)?.attributes?.smartphoneVariant
      ?.data?.attributes?.combinedPicture?.data?.attributes,
  );

  return (
    <button
      className="hidden absolute bottom-[-140px] z-10 py-2 px-4 w-72 bg-lotion rounded-lg border border-black transform-gpu lg:flex"
      onClick={handleGoToFunnel}
      onMouseEnter={handleShowPreview}
      onMouseLeave={handlehidePreview}
    >
      <div className="absolute top-[-9px] left-1/2 gap-x-2 w-4 h-4 bg-lotion rounded-sm border-t border-l border-t-black border-l-black rotate-45" />
      <div className="overflow-hidden relative basis-1/3 mb-[-1px] h-28">
        <div className="absolute w-full h-full">
          {stripped && (
            <StrapiImage image={stripped} sizes="10vw" size="small" />
          )}
        </div>
      </div>
      <div className="mt-2 ml-2">
        <p className="text-lg font-medium text-left">
          {i18n.t(["devices", model])}
        </p>
        <p className="text-sm text-left">
          {i18n.t("size.gb", { size: storage })} - {i18n.t(["colors", color])}
        </p>
        <p className="mt-3 w-fit h-6 bg-yellow">
          <span className="font-semibold">
            {formatToCurrency("fr-FR", "EUR", price)}
          </span>
          {i18n.t("navbar.monthPrice")}
        </p>
      </div>
    </button>
  );
};
