import decode from "jwt-decode";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import Cookies from "universal-cookie";

import { config } from "./config.utils";

const cookies = new Cookies();

const getCustomerId = (pathname: string) => {
  const isB2b = pathname.startsWith("/business");
  const jwt = cookies.get(
    isB2b ? config.b2b_session_cookie : config.session_cookie,
  );

  if (jwt) {
    const decoded = decode<{ uid: number }>(jwt);

    return decoded?.uid;
  }

  return null;
};

export function useCustomerId() {
  const { pathname } = useRouter();

  const customerUid = getCustomerId(pathname);
  const [customerId, setCustomerId] = useState<number | null>(customerUid);

  const handleCookieChange = useCallback(() => {
    setCustomerId(getCustomerId(pathname));
  }, [pathname]);

  useEffect(() => {
    cookies.addChangeListener(handleCookieChange);
    return cookies.removeChangeListener(handleCookieChange);
  }, [handleCookieChange]);

  return customerUid || customerId;
}
