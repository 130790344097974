import cx from "classnames";
import React, { HTMLProps } from "react";

import { useSettings } from "shared/SettingsProvider/useSettings";

import LinkArrowSVG from "./link-arrow.svg";

type NavLinkProps = HTMLProps<HTMLAnchorElement>;

export const NavLink = React.forwardRef(
  ({ children, className, ...props }: NavLinkProps, _ref) => {
    const { isBusiness } = useSettings();

    return (
      <a
        className={cx(
          "flex gap-6 items-center text-2xl font-medium lg:text-lg",
          isBusiness && "text-white",
          className,
        )}
        {...props}
      >
        {children}
        {!isBusiness && <LinkArrowSVG className="lg:hidden" />}
      </a>
    );
  },
);
