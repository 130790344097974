import cx from "classnames";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { Banner } from "ui/Banner";
import { useCustomerId } from "utils/use-customer-id.utils";
import { useI18n } from "utils/with-i18n.utils";

import { BurgerMenu } from "./BurgerMenu";
import { LoginBlock } from "./LoginBlock";
import { ContactUs } from "./LoginBlock/ContactUs";
import { MobileLoginButton } from "./MobileLoginButton";
import { NavLinks } from "./NavLinks";
import { NavLogo } from "./NavLogo";
import { useBurgerMenuActions } from "./useBurgerMenuActions";

type NavbarProps = { withBanner?: boolean };

export const Navbar = ({ withBanner }: NavbarProps) => {
  const i18n = useI18n();
  const { isBlackFriday, isBusiness } = useSettings();

  const customerId = useCustomerId();

  const { handleCloseBurgerMenu, handleOpenBurgerMenu, isBurgerMenuOpen } =
    useBurgerMenuActions();

  return (
    <nav
      className={cx(
        isBurgerMenuOpen &&
          "fixed lg:static lg:h-fit w-full h-screen z-50 bg-lotion",
        isBusiness ? "bg-black" : "pt-10 lg:pt-0",
      )}
    >
      <div
        className={cx(
          "flex justify-between items-center lg:text-lg",
          isBusiness
            ? "lg:py-[18px] lg:px-[32px] pt-[40px] pb-[12px] px-[18px] bg-black"
            : "lg:py-5 lg:px-6 lg:mt-0 px-4 pb-4",
        )}
      >
        <BurgerMenu
          onOpenBurgerMenu={handleOpenBurgerMenu}
          onCloseBurgerMenu={handleCloseBurgerMenu}
          isBurgerMenuOpen={isBurgerMenuOpen}
        />

        <NavLogo />

        <div
          className={cx(
            "hidden lg:flex",
            isBusiness ? "text-white gap-x-[24px]" : "gap-x-6",
          )}
        >
          <NavLinks />
        </div>

        <LoginBlock customerId={customerId} />
      </div>

      {isBurgerMenuOpen && (
        <div
          className={cx(
            "flex z-50 flex-col gap-y-9 items-center py-24 w-full h-screen bg-yellow border-t lg:hidden",
            isBusiness && "bg-black",
          )}
        >
          <NavLinks />

          {isBusiness ? (
            <ContactUs isBurgerMenuOpen={isBurgerMenuOpen} />
          ) : (
            <MobileLoginButton customerId={customerId} />
          )}
        </div>
      )}
      {withBanner && (
        <Banner
          content={
            isBlackFriday
              ? [
                  i18n.t("blackfriday"),
                  i18n.t("blackfriday"),
                  i18n.t("blackfriday"),
                ]
              : [
                  i18n.t("marquee.header1"),
                  i18n.t("marquee.header2"),
                  i18n.t("marquee.header3"),
                ]
          }
        />
      )}
    </nav>
  );
};
