import Link from "next/link";

import { useI18n } from "utils/with-i18n.utils";

import { NavLink } from "./NavLink";

export const BusinessNavLinks = () => {
  const i18n = useI18n();

  return (
    <>
      <Link href="/business" passHref>
        <NavLink className="lg:pl-[18px]">
          {i18n.t("navbar.business.business")}
        </NavLink>
      </Link>

      <Link href="/business/gestion-de-flotte" passHref>
        <NavLink className="lg:border-[3px] lg:border-transparent">
          {i18n.t("navbar.business.fleetManagement")}
        </NavLink>
      </Link>

      <Link href="/business/catalogue" passHref>
        <NavLink className="lg:border-[3px] lg:border-transparent">
          {i18n.t("navbar.business.ourDevices")}
        </NavLink>
      </Link>

      <Link href="/" passHref>
        <NavLink className="lg:border-[3px] lg:border-transparent">
          {i18n.t("navbar.business.privateOffer")}
        </NavLink>
      </Link>
    </>
  );
};
