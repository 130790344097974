import UserWhiteSVG from "./user-white.svg";

type LoggedUserIconProps = { customerName?: string };

export const LoggedUserIcon = ({ customerName }: LoggedUserIconProps) => (
  <>
    <p className="flex justify-center items-center w-3 h-3 font-bold text-white uppercase lg:hidden">
      {customerName?.charAt(0)}
    </p>
    <div className="hidden lg:block">
      <UserWhiteSVG />
    </div>
  </>
);
