import cx from "classnames";
import React, { HTMLProps, PropsWithChildren } from "react";

type FooterLinkProps = PropsWithChildren<HTMLProps<HTMLAnchorElement>>;

export const FooterLink = React.forwardRef(
  ({ children, className, ...props }: FooterLinkProps, _ref) => {
    return (
      <a
        className={cx(
          "text-lg leading-none no-underline cursor-pointer text-light-dark hover:text-black hover:underline",
          className,
        )}
        {...props}
      >
        {children}
      </a>
    );
  },
);
