import cx from "classnames";
import { useRouter } from "next/router";
import { HTMLAttributes, useEffect, useState } from "react";
import { match } from "ts-pattern";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { Alternative, Button, Shadow } from "ui/Button";
import { useI18n } from "utils/with-i18n.utils";

import { LoggedUserIcon } from "./LoggedUserIcon";
import ResumeOrderSVG from "./resume-order.svg";
import UserWhiteSVG from "./user-white.svg";
import UserSVG from "./user.svg";

type LoginButtonProps = HTMLAttributes<HTMLButtonElement> & {
  customerName?: string;
  hasSession: boolean;
  hasIncompleteOrder: boolean;
};

export const LoginButton = ({
  customerName,
  hasSession,
  hasIncompleteOrder,
  className,
  ...props
}: LoginButtonProps) => {
  const i18n = useI18n();
  const { push } = useRouter();

  const { isBusiness } = useSettings();

  const [alternative, setAlternative] = useState<Alternative>("secondary");

  const handleGoToLogin = () => push("/login");

  const handleGoToAccount = () => push("/espace-membre");

  const handleGoToFunnel = () => push("/commander/recapitulatif");

  const handleGoToBusinessLogin = () => push("/business/login");

  useEffect(() => {
    const newAlternative = match<{}, Alternative>({
      hasIncompleteOrder,
      hasSession,
      isBusiness,
    })
      .with({ hasIncompleteOrder: true }, () => "primary")
      .with({ hasSession: true }, () => "enabled")
      .with({ isBusiness: true }, () => "transparent")
      .otherwise(() => "secondary");

    setAlternative(newAlternative);
  }, [hasIncompleteOrder, hasSession, isBusiness]);

  const shadow = match({ isBusiness })
    .with({ isBusiness: true }, () => Shadow.None)
    .otherwise(() => Shadow.Black);

  const { handleClick, Icon, locale } = match({
    hasIncompleteOrder,
    hasSession,
    isBusiness,
  })
    .with({ hasIncompleteOrder: true }, () => ({
      handleClick: handleGoToFunnel,
      Icon: ResumeOrderSVG,
      locale: i18n.t("navbar.resumeOrder"),
    }))
    .with({ hasSession: true }, () => ({
      handleClick: handleGoToAccount,
      Icon: () => <LoggedUserIcon customerName={customerName} />,
      locale: i18n.t("navbar.helloCustomer", { name: customerName }),
    }))
    .with({ isBusiness: true }, () => ({
      handleClick: handleGoToBusinessLogin,
      Icon: UserWhiteSVG,
      locale: i18n.t("navbar.business.logIn"),
    }))
    .otherwise(() => ({
      handleClick: handleGoToLogin,
      Icon: UserSVG,
      locale: i18n.t("navbar.customerAccount"),
    }));

  return (
    <Button
      onClick={handleClick}
      {...props}
      className={className}
      alternative={alternative}
      shadow={shadow}
      borderOnMobile={!isBusiness}
      aria-label="login"
    >
      <div
        className={cx("flex flex-row items-center", !isBusiness && "gap-x-2")}
      >
        <Icon />

        <span
          className={cx(
            "hidden lg:block",
            isBusiness && "font-medium pl-[10px]",
          )}
        >
          {locale}
        </span>
      </div>
    </Button>
  );
};
