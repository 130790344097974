import cx from "classnames";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { HorizontalSeparator } from "ui/HorizontalSeparator";

import { FirstSection } from "./FirstSection";
import { FourthSection } from "./FourthSection";
import { NumeriqueLabelsAndSocialMedia } from "./NumeriqueLabelsAndSocialMedia";
import { SecondSection } from "./SecondSection";
import { ThirdSection } from "./ThirdSection";

export const Footer = () => {
  const { isBusiness } = useSettings();

  return (
    <footer className="overflow-hidden">
      <div
        className={cx(
          "flex flex-col items-center lg:px-28 py-12 lg:pb-8",
          !isBusiness && "bg-yellow",
        )}
      >
        <div className="flex flex-col w-max">
          <div className="flex flex-col gap-3 justify-between xl:flex-row xl:gap-12">
            <div className="flex flex-row justify-center lg:justify-between">
              <section>
                <FirstSection />
              </section>
              <section className="hidden lg:flex xl:hidden">
                <ThirdSection />
              </section>
            </div>
            <section className="lg:hidden">
              <NumeriqueLabelsAndSocialMedia />
            </section>
            <HorizontalSeparator
              className={cx(
                "block w-full !border-black/35 lg:hidden",
                !isBusiness && "mt-6 mb-8",
              )}
            />

            <section className="flex justify-center mt-0 lg:hidden lg:shrink-0 lg:mt-8 xl:flex xl:mt-0">
              <SecondSection />
            </section>
            <HorizontalSeparator
              className={cx("block w-full !border-black/35 lg:hidden")}
            />

            <section className="mt-4 lg:hidden xl:flex xl:mt-0">
              <ThirdSection />
            </section>
          </div>
          <section className="hidden lg:block lg:mt-5">
            <NumeriqueLabelsAndSocialMedia />
          </section>
          <section className="hidden ml-10 lg:flex lg:mt-10 xl:hidden">
            <SecondSection />
          </section>
        </div>
      </div>
      <div className="py-3 px-5 mt-5 bg-white lg:px-20 lg:pb-4 lg:mt-0">
        <section>
          <FourthSection />
        </section>
      </div>
    </footer>
  );
};
