import cx from "classnames";
import Link from "next/link";

import { useSettings } from "shared/SettingsProvider/useSettings";

import MobileClubBusinessGroup from "./mobile-club-business-logo-group.svg";
import MobileClubGroup from "./mobile-club-logo-group.svg";

export const Logo = () => {
  const { isBusiness } = useSettings();

  return (
    <Link href={isBusiness ? "/business" : "/"}>
      <a className={cx(isBusiness && "pt-2")} aria-label="return to home">
        {isBusiness ? <MobileClubBusinessGroup /> : <MobileClubGroup />}
      </a>
    </Link>
  );
};
