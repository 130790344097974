import dayjs from "dayjs";
import guys from "images/newsletter/guys.webp";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import { MailEntry } from "app/newsletter/MailEntry";
import { Surprise } from "app/newsletter/Surprise";
import { Thanks } from "app/newsletter/Thanks";
import Cross from "shared/WebsiteModal/cross.svg";
import { StaticImage } from "ui/StaticImage";
import { getCookie, getToken, setCookie } from "utils/cookies.utils";

const DELAY_BEFORE_OPEN = 10000; // 10 sec

export enum NewsletterStep {
  SurpriseStep,
  MailEntryStep,
  ThanksStep,
}

type NewsletterProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  initialStep?: NewsletterStep;
};

export const Newsletter = ({
  isOpen,
  setIsOpen,
  initialStep = NewsletterStep.SurpriseStep,
}: NewsletterProps) => {
  const router = useRouter();
  const [step, setStep] = useState(initialStep);

  useEffect(() => {
    if (initialStep !== NewsletterStep.MailEntryStep) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, DELAY_BEFORE_OPEN);

      return () => clearTimeout(timer);
    }
  });

  const isLoggedIn = Boolean(getToken());
  const hasAlreadyBeenPrompted = Boolean(getCookie("hasSeenNewsletterPrompt"));
  const isExcludedPage = [
    "/confidentialite",
    "/login",
    "/business/login",
  ].includes(router.pathname);

  if (
    (isLoggedIn || isExcludedPage || hasAlreadyBeenPrompted) &&
    initialStep !== NewsletterStep.MailEntryStep
  ) {
    return null;
  }

  const close = () => {
    setIsOpen(false);
    setCookie(
      "hasSeenNewsletterPrompt",
      "true",
      dayjs().add(30, "day").toDate(),
    );
  };

  return (
    <Modal
      className="flex justify-center items-center w-screen h-screen"
      style={{
        content: { backgroundColor: "rgba(20,20,20,0.32)" },
      }}
      overlayClassName={{
        base: "fixed inset-0 z-50 newsletter-modal-overlay",
        afterOpen: "newsletter-modal-overlay--after-open",
        beforeClose: "",
      }}
      isOpen={isOpen}
    >
      <div className="relative w-[80vw] h-fit bg-white rounded-xl lg:w-[55rem] newsletter-modal-content">
        <Cross
          className="absolute top-4 right-4 z-10 scale-75 hover:cursor-pointer lg:scale-100"
          onClick={close}
        />
        <div className="flex w-full h-full">
          <div
            className="flex flex-col justify-center items-center pt-10 pb-5 max-w-full"
            style={{ flex: 7 }}
          >
            {step === NewsletterStep.SurpriseStep && (
              <Surprise onClick={() => setStep(NewsletterStep.MailEntryStep)} />
            )}
            {step === NewsletterStep.MailEntryStep && (
              <MailEntry onClick={() => setStep(NewsletterStep.ThanksStep)} />
            )}
            {step === NewsletterStep.ThanksStep && (
              <Thanks onClick={() => close()} />
            )}
          </div>
          <div
            className="hidden relative border-l border-l-black lg:block"
            style={{ flex: 4 }}
          >
            <StaticImage
              alt="newsletter decorative image"
              src={guys}
              layout="fill"
              className="rounded-r-xl"
              objectFit="cover"
              objectPosition="top"
              placeholder="blur"
              priority
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
