import { useI18n } from "utils/with-i18n.utils";

import LucieSVG from "./Lucie.svg";
import NRLabelSVG from "./NRLevel1.svg";

export const NumeriqueResponsableLabel = () => {
  const i18n = useI18n();

  return (
    <div className="flex flex-col">
      <div className="flex gap-3">
        <a href="https://label-nr.fr/" aria-label="label numeric responsable">
          <NRLabelSVG />
        </a>
        <a
          href="https://agence-lucie.com/"
          className="pt-2"
          aria-label="label lucie"
        >
          <LucieSVG />
        </a>
      </div>
    </div>
  );
};
