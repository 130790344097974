import { useState } from "react";

import { Newsletter, NewsletterStep } from "app/newsletter";
import { Button } from "ui/Button";
import { useI18n } from "utils/with-i18n.utils";

type NewsletterProps = {
  initialStep?: string;
};

export const NewsletterFooter = (initialStep: NewsletterProps) => {
  const i18n = useI18n();
  const [isOpen, setIsOpen] = useState(false);
  const handleSetIsOpen = (value: boolean) => {
    setIsOpen(value);
  };

  return (
    <div className="flex flex-col items-center w-[18em] lg:items-start xl:justify-between">
      <div className="flex relative mb-2.5">
        <div className="flex relative z-10 flex-nowrap w-max">
          <span
            className="relative font-whyte-trap text-[30px] font-bold text-white lg:hidden"
            style={{
              paintOrder: "stroke fill",
              WebkitTextStroke: "2px black",
              textShadow: `
                2px 2px 0 #000,
                3px 3px 0 #000
              `,
            }}
          >
            {i18n.t("footer.newsletter.amount")}
          </span>
          <span
            className="hidden relative max-w-max text-[30px] font-bold text-white lg:block"
            style={{
              paintOrder: "stroke fill",
              WebkitTextStroke: "2px black",
              textShadow: `
                2px 2px 0 #000,
                3px 3px 0 #000
              `,
            }}
          >
            {i18n.t("footer.newsletter.amount")}
          </span>
        </div>
      </div>
      <div className="mb-2 w-full text-center lg:text-left">
        {i18n.t("footer.newsletter.amountText")}
      </div>
      <Button
        type="button"
        className="inline-block mt-2 mb-0 w-72 font-semibold text-black bg-white rounded-2xl drop-shadow-md lg:mb-10 lg:w-full xl:mb-0"
        onClick={() => {
          handleSetIsOpen(true);
        }}
      >
        {i18n.t("footer.newsletter.inscriptionButton")}
      </Button>
      <Newsletter
        initialStep={NewsletterStep.MailEntryStep}
        setIsOpen={handleSetIsOpen}
        isOpen={isOpen}
      />
    </div>
  );
};
