import { NumeriqueResponsableLabel } from "./NumeriqueResponsableLabel";
import { Socials } from "./Socials";

export const NumeriqueLabelsAndSocialMedia = () => (
  <div className="flex flex-col items-center w-full lg:flex-row">
    <div className="lg:justify-end">
      <NumeriqueResponsableLabel />
    </div>

    <div className="flex justify-center items-center mt-1 w-full lg:justify-end">
      <Socials />
    </div>
  </div>
);
