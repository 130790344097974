import FacebookSVG from "./facebook.svg";
import InstagramSVG from "./instagram.svg";
import LinkedinSVG from "./linkedin.svg";
import TikTokSVG from "./tiktok.svg";

export const Socials = () => (
  <div className="flex flex-col pt-8 lg:pt-0">
    <div className="flex gap-3">
      <a
        href="https://www.instagram.com/mobile.club/"
        aria-label="mobile club's instagram"
      >
        <InstagramSVG />
      </a>
      <a
        href="https://www.tiktok.com/@mobileclub_"
        aria-label="mobile club's tiktok"
      >
        <TikTokSVG />
      </a>
      <a
        href="https://www.facebook.com/joinmobileclub/"
        aria-label="mobile club's facebook"
      >
        <FacebookSVG />
      </a>
      <a
        href="https://fr.linkedin.com/company/wearemobileclub"
        aria-label="mobile club's linkedin"
      >
        <LinkedinSVG />
      </a>
    </div>
  </div>
);
