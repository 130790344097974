import CbSVG from "./cb.svg";
import LockSVG from "./lock.svg";
import MastercardSecureCodeSVG from "./mastercard.svg";
import VerifiedByVisaSVG from "./visa.svg";

export const PaymentMethods = () => {
  return (
    <section className="pt-2">
      <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-start">
        <div className="mr-2">
          <LockSVG />
        </div>

        <div className="flex justify-center items-center my-5 xl:justify-start xl:my-0">
          <div className="mr-2">
            <CbSVG />
          </div>
          <div className="mr-2">
            <VerifiedByVisaSVG />
          </div>
          <div>
            <MastercardSecureCodeSVG />
          </div>
        </div>
      </div>
    </section>
  );
};
